@import "../../styles/mediaQuery";
@import "../../styles/mixin";
@import "../../styles/variables";

.faq_wrap {
  width: 70%;
  margin-bottom: 200px;

  @include mobile {
    width: 100%;
  }
}
