/* 미디어쿼리 */
@import "./_mediaQuery";
@import "./_mixin";

/* 노토산스 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");

/* 숨긴콘텐츠 */
.a11y-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  /* position이 absolute일때만 동작, 구형 browser에서 사용 */
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}
/* 본문 기본설정 */
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
/* 스타일 초기화*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
body {
  margin: 0;
  font-family: "Noto Sans kr", sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}
a {
  color: inherit;
  text-decoration: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
img {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  -ms-user-drag: none;
  -moz-user-drag: -moz-none;
  -khtml-user-drag: none;
  -webkit-user-drag: none;
}
/* ul 초기화 */
ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}
/* button 초기화 */
button {
  padding: 0;
  cursor: pointer;
  border: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Noto Sans kr", sans-serif;
}
input {
  border: none;
  font-family: "Noto Sans kr", sans-serif;
  font-weight: 500;
}
/* table 초기화 */
table {
  border-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
/* 높이 초기화 */
.init-height {
  min-height: calc(100vh - 264px);
}

.formik-error-message {
  display: none;
  align-items: center;
  color: rgb(255, 91, 91);
  height: 2rem;
  justify-content: start;
  transform: translate3d(5%, 0, 0);
  font-size: 12px;
  &#error-on {
    display: flex;
    transform: translateY(6px);
  }
}

@include mobile {
  .formik-error-message {
    display: none;
    align-items: center;
    color: rgb(255, 91, 91);
    height: 2rem;
    justify-content: start;
    transform: translate3d(5%, 0, 0);
    font-size: 12px;
    &#error-on {
      display: flex;
      transform: translateY(6px);
    }
  }
}

@include tablet {
  .formik-error-message {
    display: none;
    align-items: center;
    color: rgb(255, 91, 91);
    height: 2rem;
    justify-content: start;
    transform: translate3d(5%, 0, 0);
    font-size: 12px;
    &#error-on {
      display: flex;
      transform: translateY(6px);
    }
  }
}

.loading-wrap {
  @include Flex();
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #fff;
  opacity: 1;
  top: 0;
  z-index: 999;
}

.smart_wallet_logo_img {
  width: 23px;
  margin-right: 5px;
  transform: translateY(3px);
}

@include mobile {
  html {
    font-size: 13px;
  }
}

@include tablet {
  html {
    font-size: 14px;
  }
}

.valid {
  color: $valid;
}

.invalid {
  color: $invalid;
}

.flag {
  width: 50px;
}

.point_color {
  color: #3058a4;
}

.point_color_bg {
  background-color: #3058a4;
}

.confirm-page-container-header__back-button-container {
  visibility: hi;
}

.wrapper {
  @include Flex(center, center, column);
}
