@import "./variables";

@mixin Flex($justify: center, $align: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin modal-button($border-color: #fff, $font-color: #fff) {
  box-sizing: border-box;
  border: 2px solid $border-color;
  background-color: transparent;
  font-weight: 500;
  color: $font-color;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 50px;
  outline: 0;
  transition: all 0.3s;
  padding: 22px 76px;
  margin: 50px 15px 0px 15px;

  &:hover {
    border-color: #fff;
    background-color: #fff;
    color: #2c3035;
  }
}

@mixin button(
  $w: 200px,
  $h: 50px,
  $r: 5px,
  $c: #fff,
  $bg: $point,
  $hover: $point
) {
  width: $w;
  height: $h;
  border-radius: $r;
  color: $c;
  background-color: #eee;
  &:hover {
    background-color: $point;
  }
}
@mixin text($fs: 16px, $fw: 400, $fc: #fff) {
  font-size: $fs;
  font-weight: $fw;
  color: $fc;
}
