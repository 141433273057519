@import "../styles/mediaQuery";
@import "../styles/mixin";
@import "../styles/variables";

.mui_pagination_wrap {
  @include Flex(center, center, column);
  padding: 20px 0;

  > div {
    margin-top: 20px;

    > span {
      font-size: 14px;
      margin: 0 5px;
      padding: 5px 10px;
    }
  }
}
