@import "../../styles/mixin";
@import "../../styles/variables";
@import "../../styles/mediaQuery";

.error_section {
  @include Flex(center, center);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  .error_section_style {
    @include Flex(space-between, center, column);
    height: 200px;

    & > h2 {
      @include text(30px, 600, $point);
    }

    & > p {
      @include text(24px, 500, $gray500);
    }

    .btn_area {
      @include Flex(center, center, column);

      button {
        @include button();
        @include text(20px, 500, #fff);
        margin: 10px;
      }
    }
  }
}
