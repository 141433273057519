@import "../styles/mediaQuery";
@import "../styles/mixin";
@import "../styles/variables";

.footer {
  width: 100%;
  height: 300px;
  margin: 0 auto;
  border-top: 2px solid #eee;
  position: relative;
  transform: translateY(0%);
  color: #fff;

  > div {
    @include Flex(space-between, center);
    max-width: 1400px;
    margin: auto;
    padding: 50px;

    > div:nth-child(1) {
      font-size: 13px;

      > div {
        margin-bottom: 5px;
        > span {
          margin-right: 3px;
        }
      }

      > div:nth-child(1) {
        img {
        }
      }

      > div:nth-child(2) {
      }

      > div:nth-child(3) {
      }

      .footer_bold_text {
        font-weight: bold;
      }
    }

    > div:nth-child(2) {
      font-size: 13px;

      > div:nth-child(2) {
        @include Flex(center, flex-end, column);
        > span:nth-child(1) {
          font-size: 30px;
          font-weight: bold;
        }
      }
    }

    @include tablet {
      @include Flex(center, space-between, column);

      > div:nth-child(1) {
        font-size: 13px;

        margin-bottom: 40px;
      }

      > div:nth-child(2) {
        font-size: 13px;
      }
    }

    @include mobile {
      @include Flex(center, space-between, column);
      padding: 0 10px;

      > div:nth-child(1) {
        font-size: 13px;

        margin-bottom: 40px;
      }

      > div:nth-child(2) {
        font-size: 13px;
      }
    }
  }
}
