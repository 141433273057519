@import "../styles/mediaQuery";
@import "../styles/mixin";
@import "../styles/variables";

.content_title {
  @include Flex(center, center, column);
  padding: 100px 0;

  > span {
    font-size: 20px;
    margin-bottom: 20px;
  }

  > strong {
    color: $point;
    font-size: 26px;
  }

  @include mobile {
    padding: 50px 0;

    > span {
      font-size: 14px;
      margin-bottom: 10px;
    }

    > strong {
      color: $point;
      font-size: 20px;
    }
  }
}
