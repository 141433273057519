@import "../styles/mediaQuery";
@import "../styles/mixin";
@import "../styles/variables";

.sub_header {
  width: 100%;
  position: relative;
  @include mobile {
    margin-top: 100px;
  }

  > img {
    width: 100%;
  }

  > h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    font-weight: bold;
    font-size: 36px;

    @include mobile {
      font-size: 30px;
    }
  }
}
