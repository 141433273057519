@import "../../styles/mediaQuery";
@import "../../styles/mixin";
@import "../../styles/variables";

.notice_table_wrap {
  width: 70%;
  max-width: 1200px;
  display: table;

  @include mobile {
    width: 100%;
  }

  > div {
    @include Flex(flex-end, center);
    margin: 20px 0;
  }

  .notice_top_border {
    border-top: 2px solid $grayPoint;
  }

  > ul {
    @include Flex(space-between, center);

    padding: 10px 0;

    > li {
      text-align: center;

      &:nth-child(1) {
        width: 10%;
        @include mobile {
          width: 30px;
        }
      }
      &:nth-child(2) {
        width: 70%;
      }
      &:nth-child(3) {
        width: 15%;
        @include mobile {
          width: 80px;
        }
      }
    }
  }

  .clickable_notice {
    cursor: pointer;

    &:hover {
      background-color: rgba(48, 88, 164, 0.2);
    }
  }

  .flag_notice {
    background-color: $gray30;
  }
}

.search_notice_handle_input {
  padding: 4px 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-right: 5px;
}

.search_notice_handle_btn {
  padding: 5px 10px;
  border-radius: 10px;
}

.each_notice_wrap {
  @include Flex(center, center, column);
  width: 80%;
  max-width: 1000px;

  @include mobile {
    width: 100%;
  }

  margin-bottom: 100px;
}

.goback_wrap {
  @include Flex(flex-end, center);
  width: 100%;
  margin-bottom: 40px;

  > span {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #eee;
    cursor: pointer;

    &:hover {
      background-color: rgba(48, 88, 164, 0.2);
    }
  }
}

.notice_info_wrap {
  width: 90%;
  margin-bottom: 80px;

  > h2 {
    margin-bottom: 10px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  > div {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 10px;
  }
}
