@import "../styles/mediaQuery";
@import "../styles/mixin";
@import "../styles/variables";

.header {
  @include Flex(space-between, center);
  width: 100%;
  position: fixed;
  height: 90px;
  background-color: transparent;
  padding: 0 20px;
  z-index: 50;
  color: #000;
  transition: ease-in-out 0.3s;

  @include mobile {
    @include Flex(space-between, center, column);
    background-color: #fff;
    color: #000;
    height: 80px;
    padding: 10px 0;
  }

  > div:nth-child(1) {
    img {
      @include mobile {
        width: 150px;
        margin-bottom: 20px;
      }
    }
  }

  > div:nth-child(2) {
    span {
      margin-left: 10px;
      padding: 5px 10px;
      cursor: pointer;
      transition: ease-in-out 0.2s;

      &:hover {
        color: $point;
        font-weight: bold;
      }
    }

    @include mobile {
      span {
        margin: 10px;
        padding: 0;
      }
    }
  }
}

.now_route {
  color: $point;
  font-weight: bold;
}

.scroll_header {
  background-color: #fff;
  color: #000;
}

// .logo_filter {
//   filter: invert(360) brightness(200%);
// }
