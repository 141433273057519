$valid: #507df1;
$invalid: #e94786;

$point: #3058a4;
$keyColor: #ede577;
$grayPoint: #6b7071;

$gray900: #222222;
$gray700: #333333;
$gray650: #4c4c4c;
$gray600: #515151;
$gray550: #666666;
$gray500: #717171;
$gray450: #888888;
$gray400: #999999;
$gray200: #d8d8d8;
$gray100: #e3e3e3;
$gray50: #eeeeee;
$gray30: #f7f8f9;
