@import "../../styles/mediaQuery";
@import "../../styles/mixin";
@import "../../styles/variables";

.banner_img_wrap {
  position: relative;

  @include tablet {
    height: auto;
  }

  @include mobile {
    margin-top: 50px;
    height: auto;
  }

  > img {
    width: 100%;
  }

  > div {
    @include Flex(center, center, column);
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #000;

    > strong {
      font-size: 70px;
      margin-bottom: 20px;
    }

    > span {
      font-size: 18px;
    }

    @include tablet {
      > strong {
        font-size: 50px;
        margin-bottom: 10px;
      }

      > span {
        font-size: 14px;
      }
    }

    @include mobile {
      > strong {
        font-size: 24px;
        margin-bottom: 10px;
      }

      > span {
        font-size: 12px;
      }
    }
  }
}

.home_token_info {
  @include Flex(center, center);
  width: 100%;
  height: 1000px;

  position: relative;
  background: url("../../assets/img/calliope_bg_white_1.png") no-repeat center;
  background-attachment: fixed;

  > div {
    @include Flex(center, center, column);
    padding: 100px 0;
    width: 100%;

    > div:nth-child(1) {
      @include Flex(center, flex-start, column);
      width: 80%;
      margin-bottom: 40px;
      > span {
        font-size: 26px;
        color: #343434;

        @include mobile {
          font-size: 13px;
        }
      }

      > strong {
        font-size: 40px;
        color: #0072b4;

        @include mobile {
          font-size: 20px;
        }
      }
    }

    > div:nth-child(2) {
      @include Flex(space-between, center);
      width: 80%;

      > div {
        @include Flex(center, center, column);
        border: 2px solid #d1d1d161;
        border-radius: 30px;
        width: 18%;
        height: 350px;
        background-color: rgba(255, 255, 255, 0.5);

        > img {
          width: 80px;
          margin-bottom: 20px;
        }

        > strong {
          text-align: center;
          font-weight: bold;
          font-size: 18px;
        }

        > span {
          width: 90%;
          text-align: center;
          word-break: break-all;
        }
      }

      @include mobile {
        @include Flex(center, center, column);
        width: 95%;

        > div {
          @include Flex(center, center);
          width: 100%;
          height: 100px;
          border-radius: 10px;
          margin-bottom: 5px;
          padding: 0 20px;

          > img {
            width: 50px;
            margin: auto;
          }

          > strong {
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            width: 100px;
            margin: 0 20px;
          }

          > span {
            width: 90%;
            text-align: center;
            word-break: break-all;
          }
        }
      }
    }
  }
}

.home_usable_point {
  @include Flex(space-evenly, flex-start);
  width: 100%;
  background-size: cover;
  background: url("../../assets/img/calliope_bg_blue_1.png") no-repeat center;
  color: #fff;
  padding: 400px 0;

  > div:nth-child(1) {
    @include Flex(center, center, column);
    width: 500px;

    > strong {
      width: 100%;
      font-size: 40px;
      margin-bottom: 20px;
    }

    > p {
      width: 100%;
      font-size: 20px;
    }
  }

  > div:nth-child(2) {
    width: 40%;

    > p {
      border: 1px solid #fff;
      background-color: rgba(255, 255, 255, 0.9);
      color: #000;
      border-radius: 5px;
      padding: 20px 30px;

      margin-bottom: 15px;
    }
  }

  @include tablet {
    @include Flex(center, center, column);

    > div:nth-child(1) {
      width: 90%;
      margin-bottom: 40px;
    }

    > div:nth-child(2) {
      width: 90%;

      > p {
        text-align: center;
      }
    }
  }

  @include mobile {
    @include Flex(center, center, column);

    > div:nth-child(1) {
      width: 90%;
      margin-bottom: 40px;
      > strong {
        font-size: 22px;
      }

      > p {
        font-size: 14px;
      }
    }

    > div:nth-child(2) {
      width: 90%;

      > p {
        text-align: center;
      }
    }
  }
}

.home_white_paper {
  @include Flex(center, center);
  width: 100%;
  padding: 200px 0;
  position: relative;
  background: url("../../assets/img/calliope_bg_white_2.png") no-repeat center;
  background-attachment: fixed;

  > div {
    width: 80%;

    > div:nth-child(1) {
      @include Flex(space-between, center, column);
      margin: auto;
      margin-bottom: 40px;

      > div:nth-child(1) {
        @include Flex(flex-start, center, column);
        width: 100%;
        padding: 0 10px;

        > span {
          width: 100%;
          margin-bottom: 20px;
          font-size: 28px;

          @include mobile {
            font-size: 20px;
          }
        }

        > strong {
          width: 100%;
          font-size: 32px;
          margin-bottom: 20px;

          @include mobile {
            font-size: 24px;
          }
        }
      }

      > div:nth-child(2) {
        @include Flex(flex-end, center);

        width: 100%;
        padding: 0 10px;

        > button {
          width: 150px;
          padding: 10px 0;
          border-radius: 30px;
          background-color: #bbb;
          font-size: 16px;

          @include mobile {
            width: 100px;
            font-size: 14px;
          }
        }
      }
    }

    > div:nth-child(2) {
      @include Flex(space-between, center);
      padding: 10px;
      width: 100%;

      @include mobile {
        @include Flex(space-between, center, column);
        padding: 0 10px;
      }

      > div:nth-child(1) {
        @include Flex(space-between, center, column);

        @include mobile {
          @include Flex(space-between, center);
          width: 100%;
          padding: 0 10px;
        }

        > button {
          margin-bottom: 10px;
          width: 150px;
          padding: 20px 0;
          border-radius: 10px;
          background-color: $keyColor;

          @include mobile {
            width: 30%;
          }
        }
      }

      > div:nth-child(2) {
        width: 80%;

        @include mobile {
          width: 100%;
        }

        .banner_img_wrap {
          width: 100%;
        }
      }
    }
  }
}

.home_listed {
  @include Flex(center, center, column);
  width: 100%;
  background-color: $keyColor;
  padding: 150px 0;

  > strong {
    margin-bottom: 20px;
    font-size: 30px;
  }

  > span {
    margin-bottom: 100px;
    font-size: 18px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;

    @include tablet {
      width: 90%;
    }

    @include mobile {
      width: 100%;
    }

    > div {
      width: calc(25% - 10px);
      margin-bottom: 10px;
      box-sizing: border-box;

      @include mobile {
        width: calc(50% - 10px);
      }

      > img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.home_partners {
  @include Flex(center, center, column);
  width: 100%;
  background-color: $grayPoint;
  padding: 150px 0;
  color: white;

  > strong {
    margin-bottom: 20px;
    font-size: 30px;
  }

  > span {
    margin-bottom: 100px;
    font-size: 18px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    width: 70%;

    @include tablet {
      width: 90%;
    }

    @include mobile {
      width: 100%;
    }

    > div {
      border: 1px solid white;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.5);
      width: calc(19%);
      margin: 4px;
      box-sizing: border-box;

      @include mobile {
        width: calc(50% - 10px);
      }

      > img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.home_company_info {
  @include Flex(center, center, column);
  width: 100%;
  padding: 200px 0;
  position: relative;
  background: url("../../assets/img/calliope_bg_white_3.png") no-repeat center;
  background-attachment: fixed;

  > strong {
    margin-bottom: 80px;
    font-size: 40px;

    @include mobile {
      font-size: 26px;
    }
  }

  > span {
    @include Flex(center, center);
    margin-bottom: 30px;
    font-size: 24px;

    @include mobile {
      font-size: 18px;
    }

    > img {
      width: 50px;
      margin-right: 10px;
    }
  }
}
